/* ContactMe.css */

.contact-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    padding: 20px;
}

.contact-form {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #333;
    color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.contact-form h1 {
    text-align: center;
    margin-bottom: 30px;
}

.contact-form input[type="email"],
.contact-form textarea {
    width: 100%;
    padding: 15px 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 2px solid #555;
    background: #222;
    color: #ccc;
    border-radius: 5px;
}

.contact-form label {
    font-weight: bold;
    color: #aaa;
    display: block;
    margin-bottom: 5px;
}

.contact-form .send-button {
    width: 100%;
    padding: 12px 20px;
    background-color: #5c67f2;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.contact-form .send-button:hover,
.contact-form .send-button:focus {
    background-color: #4a54e1;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .contact-form {
        margin: 10px;
        padding: 15px;
    }
}
