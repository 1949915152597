/* Home.css */

.home-layout {
  background-color: black;
  color: white;
}

.hero-section {
  min-height: auto;
  min-width: auto;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
}

.tech-stack {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category {
  width: 100%;
  margin-bottom: 20px;
}

.category h2 {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 10px;
  border-bottom: 3px solid #FF474C;
  padding-bottom: 15px;
}

.category-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 10px;
}

.tech-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 10px;
}

.tech-item svg {
  width: 50px;
  height: 50px;
}

.tech-item p {
  font-size: 0.85rem;
}

@media (max-width: 768px) {
  .hero-section {
    padding: 10px;
  }

  .category-items {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .tech-item {
    padding: 8px;
  }

  .tech-item svg {
    width: 40px;
    height: 40px;
  }

  .tech-item p {
    font-size: 0.75rem;
  }
}