/* PoweredBy.css */

.powered-by-section {
color: white;
background: #121212;
margin: 0 auto;
padding: 2rem;
text-align: center;
}

.container {
max-width: 1140px;
margin: 0 auto;
padding: 0 15px;
}

.powered-by-text {
font-size: 1.5rem;
color: #FF474C;
margin-bottom: 1rem;
display: block;
}

.tech-icons {
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
gap: 1.5rem;
}

.icon {
display: flex;
flex-direction: column;
align-items: center;
font-size: 3rem;
}

.icon p {
margin-top: 0.5rem;
font-size: 1.2rem;
}

@media (max-width: 768px) {
.powered-by-section {
    padding: 1rem;
}

.powered-by-text {
    font-size: 1.2rem;
}

.tech-icons {
    gap: 0.5rem;
}

.icon {
    font-size: 2rem;
}

.icon p {
    font-size: 0.9rem;
}
}  