/* HeroSection.css */

.hero-section {
  color: white;
  background-color: #000;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-text h1 {
  font-size: 2.5rem;
  margin-bottom: 4rem;
  font-weight: bold;
  color:#FF474C;
}

.hero-text {
  flex-basis: 100%;
  max-width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  text-align: center;
}

.location-weather {
  margin-top: 2.5rem;
  margin-bottom: 1.2rem;
  font-size: 1.3rem;
}

.availability {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  font-size: 1.3rem;
}

.hero-image {
  flex-basis: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  order: -1;
}

.hero-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (min-width: 768px) {
  .hero-section {
    flex-direction: row;
    text-align: left;
  }

  .hero-text, .hero-image {
    flex-basis: 50%;
    max-width: 50%;
  }

  .hero-text {
    padding-right: 1rem;
    margin-bottom: 0;
  }

  .hero-image {
    padding-left: 1rem;
    order: 0;
  }

  .location-weather,
  .availability {
    order: 1;
  }
}