.tech-stack {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #0A0A0A;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.tech-stack h2 {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 20px;
  color: #FFFFFF;
  font-weight: bold;
}

.tech-stack h2::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background: #FF474C;
  margin: 20px auto;
  margin-bottom: -5px;
}

.category-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px; /* Increased margin-bottom for more space between rows */
}

.category {
  flex: 1;
  margin: 0 20px; /* Increased margin for more space between categories */
}

.category h3 {
  font-size: 1rem;
  margin-top: 50px;
  margin-bottom: 10px; /* Increased margin-bottom for more space below the heading */
  color: #61DAFB;
  text-align: center;
}

.tech-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Increased gap for more space between tech items */
}

.tech-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px; /* Increased padding for larger touch areas */
  background-color: rgba(255, 255, 255, 0.1);
  color: #000000;
  border-radius: 10px;
  transition: transform 0.3s;
  width: 90px; /* Increased width */
  height: 90px; /* Increased height */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tech-item:hover {
  transform: scale(1.05);
}

.tech-item svg {
  width: 40px; /* Increased icon size */
  height: 40px; /* Increased icon size */
  margin-bottom: 10px; /* Increased margin-bottom for more space between icon and text */
}

.tech-item p {
  font-size: 0.8rem; /* Increased font size */
  margin: 0;
}

@media (max-width: 768px) {
  .tech-stack p {
    text-align: center;
  }

  .category-row {
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px; /* Reduced margin-bottom for less space between rows */
  }

  .category {
    margin-bottom: 5px; /* Reduced margin-bottom for less space between categories */
  }

  .tech-item {
    width: 80px; /* Adjust width for better fit on mobile */
    height: 80px; /* Adjust height for better fit on mobile */
    padding: 10px; /* Adjust padding for better spacing */
    margin: 5px; /* Add margin for spacing around tech items */
  }

  .tech-item svg {
    width: 35px; /* Adjust icon size for better fit on mobile */
    height: 35px; /* Adjust icon size for better fit on mobile */
  }

  .tech-item p {
    font-size: 0.7rem; /* Adjust font size for better readability */
  }
}