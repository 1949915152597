/* Footer.css */

.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  padding: 20px 0;
  font-size: 1rem;
  text-align: center;
}

.footer-socials {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 0px;
}

.footer-socials a {
  color: white;
  transition: color 0.3s ease;
}

.footer-socials a:hover {
  color: #FF8A65;
}

.footer p {
  margin-top: 10px;
  opacity: 0.9;
}

@media (max-width: 768px) {
  .footer {
    padding: 10px 0;
  }

  .footer-socials {
    gap: 10px;
  }

  .footer p {
    font-size: 0.9rem;
  }
}