/* Header.css */

.navbar {
  background-color: #121212;
  border-bottom: 2px solid #ff4500;
  padding: 0 1rem;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navbar-brand {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}

.navbar-brand img {
  width: 40px;
  height: 40px;
  margin-right: 0.5rem;
  border-radius: 50%;
  object-fit: cover;
}

.navbar-brand .brand-text {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
}

.navbar-brand .brand-text:hover {
  color: #FF474C;
  text-decoration: none;
}

.navbar-center {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  flex-grow: 1;
  margin-left: 4rem;
}

.navbar-right {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.navbar-social {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.nav-item {
  margin: 0 0.5rem;
}

.nav-link {
  color: #fff !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 0px;
}

.nav-link:hover {
  color: #FF474C !important;
}

.btn.btn-outline-light {
  background-color: #FF474C;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  text-transform: uppercase;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;
  font-weight: bold;
}

.btn.btn-outline-light:hover,
.btn.btn-outline-light:focus,
.btn.btn-outline-light:active {
  color: #FF474C !important;
  font-weight: bold;
}

@media (max-width: 768px) {
  .navbar {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    padding: 0 0.5rem;
    height: 70px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-brand {
    margin-left: 15px;
    flex-direction: row;
    align-items: center;
  }

  .navbar-brand img {
    width: 50px;
    height: 50px;
    margin-bottom: 0;
  }

  .navbar-brand .brand-text {
    font-size: 0.8rem;
  }

  .navbar-toggler {
    position: static;
    padding: 0.25rem 0.5rem;
    margin-right: 5px;
  }

  .navbar-toggler-icon {
    background-color: #ff4500;
    border-radius: 5px;
  }

  .navbar-collapse {
    background-color: #000;
    border-top: 1px solid;
    width: 100%;
    text-align: center;
    border-bottom: 2px solid #ff4500;
  }

  .navbar-center,
  .navbar-social,
  .navbar-right {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .navbar-center {
    margin-bottom: 1rem;
    margin-left: 0;
  }

  .navbar-social {
    margin-bottom: 1rem;
    margin-left: 1rem;
  }

  .nav-item {
    padding: 8px 0;
    width: 100%;
    text-align: center;
  }

  .nav-link {
    font-size: 0.8rem;
    display: inline-block;
    text-align: center;
  }

  .btn.btn-outline-light {
    padding: 8px 20px;
  }
}