/* App.css */

html, body, #root {
  min-height: 100%;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: black;
  font-family: 'Roboto', sans-serif;
  color: #fff;
}

* {
  box-sizing: border-box;
}