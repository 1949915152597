/* ReachMe.css */

.reach-me {
  color: #fff;
  text-align: center;
  padding: 40px 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.reach-me h1 {
  font-size: 2rem;
  color: #FF474C;
  margin-bottom: 20px;
}

.reach-me p {
  font-size: 1.25rem;
  margin-bottom: 30px;
  opacity: 0.9;
}

.reach-me-button {
  background-color: #FF474C;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  text-transform: uppercase;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;
  font-weight: bold;
  text-decoration: none;
}

.reach-me-button:hover {
  background-color: #fff;
  color: #FF474C !important;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
}
